import { Container } from "@mui/material";
import { Typography, Box, BreadcrumbList, Paragraph, Button, Dialog, Tabs } from "@okta/odyssey-react-mui";
import { useEffect, useCallback, useState } from "react";
import { deleteEvent, getEvent } from "../services/EventsAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { Link as RouterLink } from "react-router-dom";
import { ExternalLinkIcon, HomeIcon } from "@okta/odyssey-react-mui/icons";
import EventStatus from "../components/callouts/EventStatus";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../components/callouts/ErrorMessage";
import EventStaffingTab from "../components/tabs/EventStaffingTab";
import AttendeesTab from "../components/tabs/AttendeesTab";
import EventDetailsTab from "../components/tabs/EventDetailsTab";


const EventDetails = () => {

    const params = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState();

    const getDetails = useCallback(async () => {
        setIsLoading(true)
        setError()
        if (isAuthenticated) {
            getEvent(await getAccessTokenSilently(), params.eventCode)
                .then(async (response) => {
                    setEvent(response.data);
                    setIsLoading(false)
                })
                .catch((error) => { setError(error); setIsLoading(false); })
        } else {
            setEvent();
            setIsLoading(false);
        }
    }, [getAccessTokenSilently, isAuthenticated, params.eventCode])

    function requestDelete(){
        getAccessTokenSilently().then((at)=> {deleteEvent(at,params.eventCode).then(()=> {navigate('/events')})}).catch((err)=>{setError(err)})
    }

    useEffect(() => {
        if (!event) {
            getDetails()
        }
    }, [event, getDetails])
      

    return (
        <>
            <Container className='AppContent'>
                <div className="appComponent">
                    <BreadcrumbList children={[<RouterLink to='/'><HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /></RouterLink>,<RouterLink to='/events'>Event Management</RouterLink>,<Paragraph href="">{event ? event.label : params.eventCode}</Paragraph>]} />
                    
                    {event ? (
                        <Box sx={{display: 'flex', flexDirection: 'column', gap:'1.14285714rem'}}>
                            <Box sx={{display:'flex'}}>
                                
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  flexGrow: 1}}>
                                    <Typography variant="h1">{event.label}</Typography>
                                    <Box sx={{maxWidth: '300px'}}><EventStatus approvalState={event.state}/></Box>
                                </Box>
                                <Box sx={{ justifyContent: 'flex-end', display:'flex' }}>
                                    <Button label={t('deleteEvent-button',{ns:'events'})} variant='danger' onClick={()=>setDeleteDialogOpen(true)}/>
                                    <Button startIcon={<ExternalLinkIcon/>} label={t('launch')} onClick={()=>{window.open('//'+window.location.host+'/lab/create?course='+event.labContent+'&eventKey='+event.eventCode)}}/>
                                    <Dialog 
                                        isOpen={deleteDialogOpen}
                                        onClose={()=>setDeleteDialogOpen(false)}
                                        title={t('deleteEvent-title',{ns:'events'})}
                                        primaryCallToActionComponent={<Button label={t('deleteEvent-confirm',{ns:'events'})} variant='danger' onClick={()=> requestDelete()}/>}
                                        secondaryCallToActionComponent={<Button label={t('deleteEvent-cancel',{ns:'events'})} variant='floating' onClick={()=>setDeleteDialogOpen(false)}/>}
                                    >
                                        {t('deleteEvent-description',{ns:'events'})}
                                    </Dialog>
                                </Box>
                                
                            </Box>
                            {error && <ErrorMessage error={error}/> }
                            <Tabs value="details" tabs={[
                                {
                                    label: t('labDetails-details-title',{ns:'events'}),
                                    value: 'details',
                                    children: <EventDetailsTab event={event} setEvent={setEvent} setError={setError}/>                                           
                                },  
                                {
                                    children: <EventStaffingTab event={event} setEvent={setEvent} setError={setError}/>,
                                    label: t('labDetails-staffing-title',{ns:'events'}),
                                    value: 'staffing'
                                },
                                {
                                    children: <AttendeesTab event={event}/>,
                                    label: t('labDetails-attendees-title',{ns:'events'}),
                                    value: 'attendees'
                                }
                            ]}/>
                        </Box>
                    ) : (<Box>{error && <ErrorMessage error={error}/> }</Box>)}
                    {isLoading &&
                        <Loader/>
                    }
                </div>
            </Container>
        </>
    )
}

export default EventDetails;