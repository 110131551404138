import React, { useEffect, useState } from 'react';
import { List, Segment, Header, Accordion, Icon } from 'semantic-ui-react';
import './TableOfContents.css'
import useDocumentObserver from './DocumentObserver';
import useIntersectionObserver from './InteractionObserver';
import i18n from '../i18n';

const TableOfContents = ({ lab, currentStep, setStep, collapse }) => {

    const [headings, setHeadings] = useState(getHeadings())
    const activeId = useIntersectionObserver(headings.map(({ id }) => id), { rootMargin: "0% 0% -25% 0%" })
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    //monitor the dom of the lab guide for changes and redraw
    useDocumentObserver('labguide', (mutationsList, observer) => {
        setHeadings(getHeadings())
    });

    function getHeadings() {

        const documentHeadings = Array.from(document.querySelectorAll(".docframe h1, .docframe h2"))

        if(documentHeadings && documentHeadings.length > 0){
            const elements = 
                documentHeadings.map((element) => ({
                    id: element.id,
                    text: element.textContent ?? "",
                    level: Number(element.tagName.substring(1))
                }));
            return elements;
        } else {
            return []
        }
    }

    function scrollTo(hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -70;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        setIsCollapsed(collapse)
    }, [collapse])


    return (
        <Segment basic id='tableofcontents'>
            <Accordion>
                <Accordion.Title onClick={handleToggleCollapse} active={isCollapsed}>
                    <Header as='h2'>{lab.title}<Icon className='dropdownIcon' name="dropdown" /></Header>
                </Accordion.Title>
                <Accordion.Content active={!isCollapsed}>
                    <List>
                        {lab.steps?.map((step, index) => (
                            <List.Item key={index}>
                                <List.Header as='a' onClick={() => {setStep(index)}} className={index === currentStep ? 'activeStep' : ''}>
                                    {typeof step.step === 'string' ? index + 1 + ": " + step.step : index + 1 + ": "+ (step.step[i18n.language]? step.step[i18n.language] : step.step['en'])}
                                </List.Header>
                                {index === currentStep &&
                                    <List.List>
                                        {headings.map((heading, childIndex) => (<List.Item className={heading.id === activeId ? 'activeStep' : ''} key={heading.id} as='a' onClick={() => scrollTo(heading.id)}>{index + 1 + ":" + (childIndex + 1) + ": " + heading.text}</List.Item>))}
                                    </List.List>
                                }
                            </List.Item>
                        )
                        )}
                    </List>
                </Accordion.Content>
            </Accordion>
        </Segment>
    )
};

export default TableOfContents;
