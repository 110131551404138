import { useState, useEffect } from 'react';
import { Segment, Container, Header, Grid, AccordionContent, Accordion, Icon } from 'semantic-ui-react';
import AppComponent from './AppComponent';
import IDPComponent from './IDPComponent';
import './LabSegment.css'
import ResourceComponent from './ResourceComponent';

const LabSegment = ({ demo, lab, idp, labStep, collapse, vertical = false }) => {

    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        setIsCollapsed(collapse)
    }, [collapse])

    return (
        <Segment secondary className="labSegment">
            {idp && lab && demo ? (
                <Container className='components'>
                    <Accordion fluid>
                        <Accordion.Title onClick={handleToggleCollapse} active={isCollapsed}>
                            <Header className='labHeader'>{demo.name}<Icon className='dropdownIcon' name="dropdown" /></Header>
                        </Accordion.Title>
                        <AccordionContent active={!isCollapsed}>
                            <Grid stackable>
                                <Grid.Column width={16} className={vertical ? 'verticalLayout':'columnLayout'}><IDPComponent idp={idp} /></Grid.Column>
                                {[...demo.app_instances].sort((a, b) => a.label.localeCompare(b.label)).map((app_instance, index) =>
                                    lab.steps[labStep]?.applications?.includes(app_instance.applicationId) ? (
                                        <Grid.Column width={16} className={vertical ? 'labComponent verticalLayout':'labComponent columnLayout'} key={app_instance.id + "" + index}><AppComponent app={app_instance} /></Grid.Column>) : null
                                )}
                                {[...demo.resource_instances].sort((a, b) => a.label.localeCompare(b.label)).map((resource_instance, index) =>
                                    <>
                                        {lab.steps ?
                                            <>
                                            { lab.steps[labStep]?.resources?.find(x => x === resource_instance.resourceId) ? (
                                                <Grid.Column width={16} className={vertical ? 'labComponent verticalLayout':' labComponent columnLayout'} key={resource_instance.id + "" + index}><ResourceComponent resource={resource_instance} /></Grid.Column>) : null
                                            }
                                            </>
                                            : null
                                        }
                                    </>
                                )}
                            </Grid>
                        </AccordionContent>
                    </Accordion>
                </Container>
            )
                : (<Container></Container>)
            }
        </Segment>
    )
}
export default LabSegment